<template>
  <CRow class="mb-5">
    <CCol lg="4" xl="3">
      <TAside class="card">
        <CListGroup>
          <CListGroupItem>
            <CRow>
              <CCol col="12" lg="12" xl="6">
                <TMessage content="From date" bold class="mb-2" />
                <TInputDateTime
                  :value.sync="filter.start_day"
                  timeline="start"
                  placeholder="Start date"
                  class="mb-3"
                  :key="startDateKey"
                />
              </CCol>
              <CCol col="12" lg="12" xl="6">
                <TMessage content="To date" bold class="mb-2" />
                <TInputDateTime
                  :value.sync="filter.end_day"
                  timeline="end"
                  placeholder="End date"
                  class="mb-3"
                  :key="endDateKey"
                />
              </CCol>
              <CCol col="12">
                <SSelectCurrency
                  :value.sync="filter.currency_id"
                  label="Currency"
                  :prepend="[]"
                  class="mb-3"
                />
              </CCol>
              <CCol col="12">
                <SSelectAccount
                  :value.sync="filter.account_id"
                  label="Accounting account"
                  :prepend="[]"
                  class="mb-2"
                />
              </CCol>
            </CRow>
            <hr />
            <CRow>
              <CCol col="12">
                <TInputRadio
                  label="Object"
                  :list="walletable_types"
                  :value.sync="filter.walletable_type"
                  @update:value="changeWalletableType"
                  custom
                  :key="radioKey"
                  class="h-auto"
                />
                <SSelectCustomer
                  v-if="filter.walletable_type == 'user'"
                  :value.sync="filter.walletable_id"
                  label="User"
                  class="mb-2"
                />
                <SSelectSupplier
                  v-if="filter.walletable_type == 'supplier'"
                  :value.sync="filter.walletable_id"
                  label="Supplier"
                  class="mb-2"
                />
              </CCol>

              <CCol col="12" class="d-flex mt-2">
                <TButton
                  class="w-100"
                  content="Filter"
                  @click="onFilter"
                  :disabled="loading || !filter.end_day || !filter.start_day"
                />
                <TButtonClear
                  @click="clearFilter"
                  variant="outline"
                  class="ml-1"
                  size=""
                />
              </CCol>
            </CRow>
          </CListGroupItem>
          <CListGroupItem v-if="loading">
            <TSpinner :loading="loading" />
          </CListGroupItem>
          <CListGroupItem
            v-for="(object, index) in list"
            :key="`${object.transactionable_id}-${index}`"
            :color="
              selected.object_id_selected == object.transactionable_id
                ? 'primary'
                : 'secondary'
            "
            :to="
              !loading
                ? _.getAppendRouteQuery($route, {
                    object_id_selected: object.transactionable_id,
                  })
                : ''
            "
          >
            <div class="d-flex">
              <SMessageWallet
                :data="{
                  currency_id: object.currency_id,
                  object: object.user ? object.user : object.supplier,
                }"
              />
              <TMessageMoney
                :amount="object.during_balance"
                class="ml-auto"
                :currency="object.currency_id"
              />
            </div>
          </CListGroupItem>
          <CListGroupItem v-if="!list.length && !loading">
            <TMessageNotFound />
          </CListGroupItem>
          <SButtonLoadmore
            v-if="!loading && list.length"
            store="accounting.statistics_account_period"
            class="mt-2 mb-3"
          />
        </CListGroup>
      </TAside>
    </CCol>
    <CCol lg="8" xl="9">
      <CCard v-if="!lodash.isEmpty(detail) && selected.object_id_selected">
        <CCardBody>
          <CRow class="mb-2">
            <CCol class="d-flex align-items-center">
              <SMessageWallet
                :data="{
                  currency_id: detail.currency_id,
                  object: detail.user ? detail.user : detail.supplier,
                }"
                class="mb-2"
              />
              <TButton
                content="Export excel"
                icon="cid-cloud-download"
                size="sm"
                @click="exportExcel"
                class="ml-auto"
                :disabled="export_loading"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol col="12" md="4">
              <TWidgetSimple
                title="Opening balance"
                color="primary"
                alignment="left"
                class="mb-0"
              >
                <template #content>
                  <TMessageMoney
                    :amount="detail.opening_balance"
                    :currency="detail.currency_id"
                    color="primary"
                    class="h3"
                  />
                </template>
              </TWidgetSimple>
            </CCol>
            <CCol col="12" md="4">
              <TWidgetSimple
                title="During balance"
                color="primary"
                alignment="left"
                class="mb-0"
                :number="detail.during_balance"
              >
                <template #content>
                  <TMessageMoney
                    :amount="detail.during_balance"
                    :currency="detail.currency_id"
                    color="primary"
                    class="h3"
                  />
                </template>
              </TWidgetSimple>
            </CCol>
            <CCol col="12" md="4">
              <TWidgetSimple
                title="Closing balance"
                color="primary"
                alignment="left"
                class="mb-0"
                :number="detail.closing_balance"
              >
                <template #content>
                  <TMessageMoney
                    :amount="detail.closing_balance"
                    :currency="detail.currency_id"
                    color="primary"
                    class="h3"
                  />
                </template>
              </TWidgetSimple>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>

      <TableTransactions
        v-if="
          !lodash.isEmpty(detail) &&
          showTransation &&
          selected.object_id_selected
        "
        :key="transactionTBkey"
        :showVoucherDateFilter="false"
        store="accounting.transactions"
        class="mb-4"
        showReference
        :default_filter="{
          'filter[transactionable_id]': selected.object_id_selected,
          'filter[currency_id]': filterSelected.currency_id,
          'filter_between[voucher_date]': `${filterSelected.start_day},${filterSelected.end_day}`,
          'filter[debit_or_credit]': filterSelected.account_id,
          sort: '-id',
          append: 'object,wallet,references',
          include: 'preparedBy',
        }"
      />
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters } from "vuex";
import TableTransactions from "../../components/TableTransactions.vue";
import mixin from "./mixin";

export default {
  components: { TableTransactions },
  mixins: [mixin],
  data() {
    return {
      filter: {
        currency_id: "",
        start_day: this.lodash.toTimeStamp(Date.now(), "start"),
        end_day: this.lodash.toTimeStamp(Date.now(), "end"),
        walletable_type: "",
      },
      walletable_types: [
        { value: "user", label: this.$t("Customer") },
        { value: "supplier", label: this.$t("Supplier") },
      ],
      store: "accounting.statistics_account_period",
      keySelected: "object_id_selected",
    };
  },
  watch: {
    "queryRoute.object_id_selected": {
      immediate: true,
      deep: true,
      handler(newId, oldId) {
        if (oldId != newId) {
          this.showTransation = true;
          const objSelected = this.list.find(
            (x) => x.transactionable_id == newId
          );
          if (objSelected) {
            this.$store.commit(
              "accounting.statistics_account_period.select",
              objSelected.transactionable_id
            );
            this.transactionTBkey = this.lodash.resetKey("transactionTBkey");
          }
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      export_loading: "helper.exports.loading",
    }),
  },
  methods: {
    appendRoute() {
      this.lodash.appendRouteQuery(this.$router, {
        query: {
          ...this.$route.query,
          currency_id: this.filter?.currency_id,
          account_id: this.filter?.account_id,
          walletable_id: this.filter?.walletable_id || "",
          walletable_type: this.filter?.walletable_type,
          start_day: this.filter?.start_day.toString(),
          end_day: this.filter?.end_day.toString(),
        },
      });
    },
    onFilter() {
      if (this.filter.currency_id) this.showTransation = true;
      this.appendRoute();
      this.fetchPeriod(this.filter);
      this.filterSelected = this.filter;
    },
    clearFilter() {
      this.lodash.appendRouteQuery(this.$router, {
        query: {
          ...this.lodash.pick(this.$route.query, ["object_id_selected"]),
        },
      });
      this.filter = {
        currency_id: "",
        start_day: this.lodash.toTimeStamp(Date.now(), "start"),
        end_day: this.lodash.toTimeStamp(Date.now(), "end"),
        walletable_type: "",
        account_id: "",
      };
      this.radioKey = this.lodash.resetKey("radioKey");
    },
    fetchPeriod(filter) {
      this.$store
        .dispatch(
          this.store + ".apply-query",
          this.lodash.pickBy({
            start_day: filter.start_day || "0",
            end_day: filter.end_day,
            currency_id: filter.currency_id,
            account_id: filter.account_id,
            walletable_type: filter.walletable_type,
            walletable_id: filter.walletable_id,
            append: "object",
          })
        )
        .then(() => {
          const objSelected = this.list.find(
            (x) => x.transactionable_id == this.detail?.transactionable_id
          );
          if (objSelected) {
            this.transactionTBkey = this.lodash.resetKey("transactionTBkey");
            this.$store.commit(this.store + ".detail.merge", objSelected);
          }
        })
        .catch(() => {
          this.$store.commit(this.store + ".purge");
        });
    },
  },
};
</script>
